<template>
  <div
    class="timetable-item"
    :class="
      obj.conflictedList && obj.conflictedList.length > 0
        ? 'bg-clash'
        : changeBg && obj.courseName
        ? 'bg-blue'
        : ''
    "
    :style="
      'width:' +
      width +
      ';height:' +
      height +
      ';background:' +
      obj.contentBg +
      ';'
    "
  >
    <el-popover
      placement="bottom"
      :visible-arrow="obj.visibleArrow"
      :trigger="obj.id ? obj.trigger || 'hover' : 'manual'"
      @show="isChangeHoverStyle ? onShowPopoverContent() : null"
      @hide="isChangeHoverStyle ? onHidePopoverContent() : null"
    >
      <div class="popover-content">
        <div
          class="single-line content-name mt14"
          :class="changeColor ? hoverFontColor.hoverName : ''"
        >
          {{ $t("el.myTimeTable.Courses") }} {{ obj.courseName }}
        </div>
        <div
          class="single-line content-name mt14"
          :class="changeColor ? hoverFontColor.hoverName : ''"
        >
          {{ $t("el.myTimeTable.Class") }} {{ obj.className }}
        </div>
        <div
          class="single-line content-name mt14"
          :class="changeColor ? hoverFontColor.hoverName : ''"
        >
          {{ $t("el.myTimeTable.Time") }} {{ obj.coursePeriod }}
        </div>
        <div
          class="single-line content-name mt14"
          :class="changeColor ? hoverFontColor.hoverName : ''"
        >
          {{ $t("el.myTimeTable.Teacher") }} {{ obj.staffName }}
        </div>
        <div
          class="single-line content-name mt14"
          :class="changeColor ? hoverFontColor.hoverName : ''"
        >
          {{ $t("el.myTimeTable.Classroom") }} {{ obj.classroomName }}
        </div>
        <div v-if="changeBg" class="flex flex-c">
          <div class="content-btn-check" @click="onHandleCourseClick(obj)">
            {{ $t("el.myTimeTable.View") }}
          </div>
        </div>
      </div>

      <div
        slot="reference"
        class="content"
        :class="changeBg ? 'content-name-white' : 'content-name'"
      >
        <div
          v-if="changeBg && obj.courseName"
          class="changeBg-header"
          :style="'background:' + obj.headBg"
        ></div>
        <div v-if="changeBg && obj.courseName" class="changeBg-more flex">
          <div class="changeBg-more-point"></div>
          <div class="changeBg-more-point"></div>
          <div class="changeBg-more-point"></div>
        </div>
        <div
          :class="[
            'single-line',
            changeBg ? '' : 'content-course-name',
            changeColor ? hoverFontColor.hoverName : '',
            allowCourseTitleClick ? ' cursor-point' : '',
          ]"
          @click="allowCourseTitleClick ? onHandleCourseClick(obj) : null"
        >
          {{ obj.courseName }}
        </div>
        <div
          class="single-line mt8"
          :class="changeColor ? hoverFontColor.hoverName : ''"
        >
          {{ obj.className }}
        </div>
        <!-- <div
          class="single-line content-name mt8"
          :class="changeColor ? hoverFontColor.hoverName : ''"
        >
          {{ obj.staffName }}
        </div> -->
      </div>
    </el-popover>
    <div
      v-if="obj.conflictedList && obj.conflictedList.length > 0"
      class="clash-red-line"
    ></div>
    <el-popover
      placement="bottom"
      :visible-arrow="obj.visibleArrow"
      :trigger="
        obj.conflictedList && obj.conflictedList.length > 0 ? 'hover' : 'manual'
      "
    >
      <div class="popover-content">
        <div
          v-for="item in obj.conflictedList"
          style="padding-bottom: 20px"
          :key="item.id"
        >
          <div class="single-line content-course-name">
            同时段<clash-table-item-title
              :typeList="item.conflictedFields"
            ></clash-table-item-title
            >安排冲突，冲突课程为：
          </div>
          <div class="single-line content-name mt14">
            {{ $t("el.myTimeTable.Courses") }} {{ item.courseName }}
          </div>
          <div
            class="single-line content-name mt14"
            :class="
              item.conflictedFields.indexOf('className') > -1 ? 'color-red' : ''
            "
          >
            {{ $t("el.myTimeTable.Class") }} {{ item.className }}
          </div>
          <div class="single-line content-name mt14">
            {{ $t("el.myTimeTable.Time") }} {{ item.coursePeriod }}
          </div>
          <div
            class="single-line content-name mt14"
            :class="
              item.conflictedFields.indexOf('staffName') > -1 ? 'color-red' : ''
            "
          >
            {{ $t("el.myTimeTable.Teacher") }} {{ item.staffName }}
          </div>
          <div
            class="single-line content-name mt14"
            :class="
              item.conflictedFields.indexOf('classroomName') > -1
                ? 'color-red'
                : ''
            "
          >
            {{ $t("el.myTimeTable.Classroom") }} {{ item.classroomName }}
          </div>
        </div>
      </div>
      <el-image
        slot="reference"
        v-if="obj.conflictedList && obj.conflictedList.length > 0"
        class="icon-clash"
        :src="iconClash"
      ></el-image>
    </el-popover>
    <knowledge-switch
      ref="knowledgeSwitch"
      :isSwitch="isSwitch"
      :prepareShow="false"
      @giveBack="giveBack"
      @prepareBack="prepareBack"
    ></knowledge-switch>
  </div>
</template>

<script>
import { mapState } from "vuex";
import clashTableItemTitle from "./clashTableItemTitle.jsx";
import knowledgeSwitch from "@/views/components/knowledge-switch.vue";
import { courseScheduleIsOpen } from "@/api/manage/course-list.js";
import { checkAvailability } from "@/api/courseList/index";
export default {
  name: "timetableItem",
  props: {
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    },
    showBottomLine: {
      type: Boolean,
      default: true,
    },

    isChangeHoverStyle: {
      type: Boolean,
      default: false,
    },
    /**
     * 是否允许标题点击
     */
    allowCourseTitleClick: {
      type: Boolean,
      default: false,
    },
    /**
     * 变更颜色，新版本
     */
    changeBg: {
      type: Boolean,
      default: false,
    },
    hoverFontColor: {
      type: Object,
      default: () => {
        return {
          hoverDefaultName: "default-font-color",
          hoverName: "change-font-color",
        };
      },
    },

    obj: {
      type: Object,
      default: () => {
        return {
          isClash: true,
          visibleArrow: true,
        };
      },
    },
  },
  components: {
    clashTableItemTitle,
    knowledgeSwitch,
  },
  data() {
    return {
      iconClash: require("../../../assets/images/icon/icon-course-clash.png"),
      visible: false,
      showPopover: false,
      changeColor: false,
      isSwitch: false,
      isPending: false,
    };
  },
  created() {
    if (this.obj.conflictedList && this.obj.conflictedList.length > 0) {
      this.showPopover = true;
    } else {
      this.showPopover = false;
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  mounted() {},
  methods: {
    onShowPopoverContent() {
      this.changeColor = true;
    },
    onHidePopoverContent() {
      this.changeColor = false;
    },
    onHandleCourseClick(item) {
      courseScheduleIsOpen(item.id).then((res) => {
        if (res.data) {
          // 启用
          this.$nextTick(() => {
            this.$refs.knowledgeSwitch &&
              this.$refs.knowledgeSwitch.init(
                item.courseId,
                item.originTenantId,
                item.originTenantType
              );
          });
        } else {
          // 禁用
          this.$message.error(this.$t("el.myTimeTable.stop"));
        }
      });
    },
    giveBack(data, courseId, courseInfo) {
      if (this.isPending) {
        return;
      }
      this.isPending = true;
      let courseTenantInfoId =
        courseInfo.courseTenantId ||
        data.courseTenantId ||
        this.obj.courseTenantId;
      checkAvailability({ courseTenantInfoId })
        .then((res) => {
          this.$router.push({
            path: "/teaching",
            query: {
              id: data.id,
              courseId: courseId,
              from: "schoolTimetabelCheck",
              originTenantId: this.obj.originTenantId,
              originTenantType: this.obj.originTenantType,
              classId: this.obj.classId,
              importCourseId: this.obj.id,
              classType: "2", // 课程班
              className: this.obj.className,
              categoryId: courseInfo.categoryId,
              courseTenantInfoId,
            },
          });
        })
        .finally(() => {
          this.isPending = false;
        });
    },
    prepareBack(data, courseId, originTenantId, originTenantType) {
      // this.$router.push({
      //   path: '/classroom/bk-ja',
      //   query: {id: data.id + ''}
      // })
      if (this.user.officeType === 1) {
        // this.$utils.openWindow(
        //   "classroomWps/bk-kj?id=" + data.id + "&courseId=" + courseId
        // );
        console.log("bbbbb");
        this.$router.push({
          path: "/classroomWps/bk-kj",
          query: {
            id: data.id,
            courseId: courseId,
            originTenantId,
            originTenantType,
          },
        });
      } else {
        // this.$utils.openWindow(
        //   "classroom/bk-kj?id=" + data.id + "&courseId=" + courseId
        // );
        this.$router.push({
          path: "/classroom/bk-kj",
          query: {
            id: data.id,
            courseId: courseId,
            originTenantId,
            originTenantType,
          },
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.timetable-item {
  position: relative;
  padding: 16px 8px 16px 12px;
  min-height: 86px;

  .clash-red-line {
    position: absolute;
    left: 0;
    top: 0;
    width: 2px;
    height: 100%;
    background: #ff1c1c;
  }
  .icon-clash {
    width: 12px;
    height: 12px;
    position: absolute;
    right: 10px;
    bottom: 8px;
  }
}
.content {
  min-height: 54px;
  .changeBg-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 6px;
    background: #7eb2f8;
    border-radius: 0px 0px 3px 3px;
  }
  .changeBg-more {
    position: absolute;
    bottom: 9px;
    right: 7px;
    &-point {
      width: 3px;
      height: 3px;
      background: #ffffff;
      border-radius: 50%;
      margin-left: 3px;
    }
  }
  &-course-name {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
  }
  &-name {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #323232;
  }
  &-name-white {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;

    color: #ffffff;
  }
}
.content:hover {
  &-course-name {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #6049ff;
  }
  &-name {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #6049ff;
  }
}

.cursor-point {
  cursor: pointer;
}

.popover-content {
  max-height: 300px;
  overflow: auto;

  .content-btn-check {
    width: fit-content;
    height: 26px;
    padding-left: 20px;
    padding-right: 20px;
    background: #5a53f5;
    border-radius: 13px;
    text-align: center;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 26px;
    margin-top: 19px;
    margin-bottom: 10px;
    cursor: pointer;
  }
}
.default-font-color {
  color: #6049ff !important;
}
.change-font-color {
  color: #6049ff !important;
}
.change-font-color:hover {
  color: #6049ff !important;
}
.bg-clash {
  background: rgba(255, 0, 0, 0.1);
}
.bg-blue {
  background-color: #9ac6fa;
}
.ml10 {
  margin-left: 10px;
}
.ml5 {
  margin-left: 5px;
}
.mb10 {
  margin-bottom: 10px;
}
.mt10 {
  margin-top: 10px;
}
.mt8 {
  margin-top: 8px;
}
.pt10 {
  padding-top: 10px;
}
.mt14 {
  margin-top: 14px;
}
.color-red {
  color: #ff1c1c;
}
</style>
