<template>
  <div class="timetable-container" v-loading="loading">
    <div v-if="tableList.length > 0">
      <div v-for="item in tableList" :key="item.id">
        <div v-if="item.showTableTitle" class="table-title">
          {{ item.title }}
          <div class="title-line"></div>
        </div>
        <template>
          <el-table
            class="timetable"
            :data="item.tableData"
            style="width: fit-content"
            border
          >
            <template slot="empty">
              <div style="padding-top: 111px; line-height: 0">
                <img
                  src="@/assets/images/pic_empty_people@2x.png"
                  alt=""
                  style="width: 270px"
                />
              </div>
              <div
                style="
                  color: rgba(100, 108, 149, 0.25);
                  font-size: 18px;
                  font-weight: 600;
                  letter-spacing: 1px;
                  padding-bottom: 120px;
                "
              >
                {{ $t("el.common.noData") }}
              </div>
            </template>

            <template v-for="column in item.columns">
              <el-table-column
                v-if="!column.type"
                v-bind="column"
                :key="column.week"
                :width="column.width || 189"
              >
                <template slot="header" slot-scope="scope">
                  <slot
                    v-if="column.headerSlotName"
                    v-bind="scope"
                    :name="column.headerSlotName"
                  />
                  <div
                    v-else
                    class="timetable-header"
                    :class="changeBg ? '' : 'flex flex-c'"
                  >
                    <div class="timetable-header-week">
                      {{ column.week }}
                    </div>
                    <div class="timetable-header-time ml12">
                      {{ column.time }}
                    </div>
                  </div>
                </template>
                <template slot-scope="scope">
                  <!-- 使用slot插入 start -->
                  <slot
                    v-if="column.slotName"
                    v-bind="scope"
                    :name="column.slotName"
                  />
                  <!-- 使用slot插入 end -->
                  <div
                    v-else
                    :class="
                      changeBg
                        ? 'timetable-content-item-changeBg'
                        : 'timetable-content-item'
                    "
                  >
                    <div
                      v-for="(obj, i) in scope.row[column.prop]"
                      :key="obj.id"
                    >
                      <timetable-item
                        :obj="obj"
                        :allowCourseTitleClick="allowCourseTitleClick"
                        :isChangeHoverStyle="isChangeHoverStyle"
                        :hoverFontColor="hoverFontColor"
                        v-if="!changeBg"
                        :changeBg="changeBg"
                      ></timetable-item>
                      <timetable-item-change
                        :obj="obj"
                        :allowCourseTitleClick="allowCourseTitleClick"
                        :isChangeHoverStyle="isChangeHoverStyle"
                        :hoverFontColor="hoverFontColor"
                        v-else
                        :changeBg="changeBg"
                      ></timetable-item-change>
                      <div
                        v-if="i !== scope.row[column.prop].length - 1"
                        class="bottom-line"
                      ></div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                v-else-if="column.type === 'common'"
                v-bind="column"
                :key="column.prop"
                :header-align="column['header-align'] || 'center'"
                :width="column.width || 120"
              >
                <template slot="header" slot-scope="scope">
                  <slot
                    v-if="column.headerSlotName"
                    v-bind="scope"
                    :name="column.headerSlotName"
                  />
                </template>
                <template slot-scope="scope">
                  <!-- 过滤器 start -->
                  <template v-if="column.filter">
                    {{ Vue.filter(column.filter)(scope.row[column.prop]) }}
                  </template>
                  <!-- 过滤器 end -->
                  <!-- 使用slot插入 start -->
                  <slot
                    v-else-if="column.slotName"
                    v-bind="scope"
                    :name="column.slotName"
                  />
                  <!-- 使用slot插入 end -->
                  <!-- 格式化文本 start -->
                  <template v-else-if="column.formatter">
                    {{
                      column.formatter(
                        scope.row,
                        scope.column,
                        scope.row[column.prop],
                        scope.$index
                      )
                    }}
                  </template>
                  <!-- 格式化文本 end -->
                  <!-- 字典翻译 start -->
                  <template v-else-if="column.dictName">
                    {{ scope.row[column.prop] | dict(column.dictName) }}
                  </template>
                  <!-- 字典翻译 end -->
                  <!-- 默认显示值 start -->
                  <template v-else>
                    {{ scope.row[column.prop] }}
                  </template>
                  <!-- 默认显示值 end -->
                </template>
              </el-table-column>
            </template>
          </el-table>
        </template>
      </div>
    </div>
    <div v-else>
      <empty-view></empty-view>
    </div>
  </div>
</template>

<script>
import TimetableItem from "./timetableItem.vue";
import TimetableItemChange from "./timetableItemChange.vue";
import emptyView from "../emptyView/index.vue";
export default {
  name: "schoolTimetabel",
  props: {
    /**
     * hover字体颜色
     */
    isChangeHoverStyle: {
      type: Boolean,
      default: false,
    },
    /**
     * 变更颜色，新版本
     */
    changeBg: {
      type: Boolean,
      default: false,
    },
    hoverFontColor: {
      type: Object,
      default: () => {
        return {
          hoverDefaultName: "default-font-color",
          hoverName: "change-font-color",
        };
      },
    },
    /**
     * 是否允许标题点击
     */
    allowCourseTitleClick: {
      type: Boolean,
      default: false,
    },
    tableList: {
      type: Array,
      default: () => [
        {
          /**
           * 列配置
           * label: 列名称
           * prop: 显示的字段名
           *
           *
           * 以下扩展属性：
           * filter: 使用过滤器显示数据
           * slotName: 使用指定的插槽
           * formatter: 格式化数据函数（value, row, index, column）
           */
          columns: {
            type: Array,
            default: () => [],
          },

          tableData: {
            type: Array,
            default: () => [],
          },
          /** *
           * 是否显示 title
           */
          showTableTitle: {
            type: Boolean,
            default: false,
          },
          title: {
            type: String,
            default: "",
          },
        },
      ],
    },
  },
  components: { TimetableItem, TimetableItemChange, emptyView },
  data() {
    return {
      loading: false,
      iconEmpty: require("@/assets/images/icon/icon-class-empty.png"),
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.timetable-container {
  .table-title {
    position: relative;
    padding-left: 12px;

    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #323232;
    line-height: 18px;
    margin-bottom: 13px;
    .title-line {
      position: absolute;
      left: 0;
      top: 0;
      width: 4px;
      height: 18px;
      background: #5f4afe;
      border-radius: 2px;
    }
  }
  .timetable {
    margin-bottom: 30px;
    &-header {
      width: 100%;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #131313;
      line-height: 21px;
      text-align: center;
      &-week {
      }
      &-time {
      }
    }
    &-content-item {
      min-height: 117px;
      .bottom-line {
        height: 1px;
        width: calc(100% + 20px);
        margin-left: -10px;
        background: #ebeef5;
      }
    }
    &-content-item-changeBg {
      min-height: 86px;
      .bottom-line {
        height: 1px;
        width: calc(100% + 20px);
        margin-left: -10px;
        background: #ebeef5;
      }
    }
  }
  /deep/ .el-table .cell {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-break: break-all;
    line-height: 23px;
    padding: 0px 0px 0px 0px;
  }
  /deep/ .el-table td,
  .el-table th {
    padding: 0 0;
    min-width: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-overflow: ellipsis;
    vertical-align: middle;
    position: relative;
  }
}

.ml10 {
  margin-left: 10px;
}
.ml12 {
  margin-left: 12px;
}
.ml5 {
  margin-left: 5px;
}
.mb10 {
  margin-bottom: 10px;
}
.mt10 {
  margin-top: 10px;
}
.mt16 {
  margin-top: 16px;
}
.mb16 {
  margin-bottom: 16px;
}
</style>
