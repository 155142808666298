<template>
  <div class="empty-view">
    <div>
      <el-image
        class="icon-empty"
        :src="iconEmpty"
        :style="imageStyle"
      ></el-image>
      <div v-if="showEmptyText" class="empty-text">
        {{ emptyText || $t("el.common.noData") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "emptyView",
  props: {
    emptyText: {
      type: String,
      default: "",
    },
    imageStyle: {
      type: String,
      default: "",
    },
    emptyTextStyle: {
      type: String,
      default: "",
    },
    showEmptyText: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  data() {
    return {
      iconEmpty: require("@/assets/images/icon/icon-class-empty.png"),
    };
  },
  computed: {},
  methods: {},
};
</script>

<style lang="less" scoped>
.empty-view {
  text-align: center;
  .icon-empty {
    margin-top: 146px;
    margin-left: auto;
    margin-right: auto;
    width: 284px;
    height: 218px;
  }

  .empty-text {
    width: fit-content;
    font-size: 22px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(100, 108, 149, 0.25);
    line-height: 30px;
    margin-top: 9px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
