<template>
  <div class="week-start-end">
    <el-button @click="changeWeekPrev" type="primary" plain>{{
      $t("el.myTimeTable.lastWeek")
    }}</el-button>
    <el-select
      ref="weekStartEndSelect"
      class="week-start-end-select"
      v-model="weekIndex"
      :placeholder="nowWeekDay"
      :popper-append-to-body="false"
      @click.native="getNowChooseWeek"
      @change="changeWeekIndex"
    >
      <i @click="getPrev" class="el-icon-caret-top"></i>
      <el-option
        v-for="(item, index) in weekData"
        :key="index"
        v-bind="item"
      ></el-option>
      <i @click="getnext" class="el-icon-caret-bottom"></i>
    </el-select>
    <el-button @click="changeWeekNext" type="primary" plain>{{
      $t("el.myTimeTable.nextWeek")
    }}</el-button>
  </div>
</template>

<script>
import { getWeekDate } from "@/utils/index";
export default {
  name: "weekStartEndComponents",
  data() {
    return {
      weekIndex: 3, // 选中的某一条数据，切换周次会修改
      chooseWeekIndex: 3, // 真正选中的周次
      isChangeLanguage: false,
      nowWeekDay: "",
      startDay: 0,
      chooseStartDay: 0,
      endDay: -6,
      chooseEndDay: -6,
      weekData: [],
    };
  },
  created() {
    this.getWeekDateList(this.startDay, this.endDay);
    this.nowWeekDay =
      getWeekDate(this.startDay) +
      " ~ " +
      getWeekDate(this.endDay) +
      this.$t("el.myTimeTable.weeklySchedule");
  },
  mounted() {},
  watch: {
    nowWeekDay(val, old) {
      console.log(val);
      console.log(old);
      if (val !== "") {
        this.$emit(
          "changeWeekDateRange",
          {
            startDate: this.weekData[this.weekIndex].start,
            endDate: this.weekData[this.weekIndex].end,
            type: old === "" ? "first" : "",
          },
          this.isChangeLanguage
        );
      }
      this.isChangeLanguage = false;
    },
    "$i18n.locale": {
      handler(val, old) {
        console.log("week  locale", val);
        this.isChangeLanguage = true;
        this.getWeekDateList(this.startDay, this.endDay);
        // this.nowWeekDay =
        //   getWeekDate(this.startDay) +
        //   " ~ " +
        //   getWeekDate(this.endDay) +
        //   " " +
        //   this.$t("el.myTimeTable.weeklySchedule");
      },
    },
  },
  methods: {
    // 点击事件，更改选中的周次
    changeWeekIndex() {
      console.log(this.weekIndex, this.chooseWeekIndex);
      // this.startDay += (this.chooseWeekIndex - this.weekIndex) * 7;
      // this.endDay += (this.chooseWeekIndex - this.weekIndex) * 7;
      this.chooseStartDay =
        (this.chooseWeekIndex - this.weekIndex) * 7 + this.startDay;
      this.chooseEndDay =
        this.endDay + (this.chooseWeekIndex - this.weekIndex) * 7;
      console.log(this.chooseStartDay, this.chooseEndDay);
      console.log(this.startDay, this.endDay);
      this.chooseWeekIndex = this.weekIndex;
      this.nowWeekDay =
        this.weekData[this.weekIndex].start +
        " ~ " +
        this.weekData[this.weekIndex].end +
        this.$t("el.myTimeTable.weeklySchedule");
    },
    /**
     * select 下拉框的选项的 向上箭头
     * 点击切换视图
     * 如果不选中某个周次
     * 不改变现在的课表选项
     */
    getPrev() {
      this.startDay += 7 * 7;
      this.endDay += 7 * 7;
      this.weekIndex = null;
      this.getWeekDateList(this.startDay, this.endDay);
    },
    /**
     * select 下拉框的选项的 向下箭头
     * 点击切换视图
     * 如果不选中某个周次
     * 不改变现在的课表选项
     */
    getnext() {
      this.startDay = this.startDay - 7 * 7;
      this.endDay = this.endDay - 7 * 7;
      this.weekIndex = null;
      this.getWeekDateList(this.startDay, this.endDay);
    },
    // 点击上一周
    changeWeekPrev() {
      // this.startDay += 7;
      this.chooseStartDay += 7;
      console.log(this.chooseStartDay);
      console.log(this.startDay);
      this.startDay = this.chooseStartDay;
      // this.endDay += 7;
      this.chooseEndDay += 7;
      this.endDay = this.chooseEndDay;
      this.weekIndex = 3;
      this.chooseWeekIndex = 3;
      this.getWeekDateList(this.startDay, this.endDay);
    },
    // 点击下一周
    changeWeekNext() {
      this.chooseStartDay -= 7;
      console.log(this.chooseStartDay);
      console.log(this.startDay);
      this.startDay = this.chooseStartDay;
      // this.endDay += 7;
      this.chooseEndDay -= 7;
      this.endDay = this.chooseEndDay;
      this.weekIndex = 3;
      this.chooseWeekIndex = 3;
      console.log(this.nowWeekDay);
      this.getWeekDateList(this.startDay, this.endDay);
    },
    // 默认下拉框展示当前选择的周
    getNowChooseWeek() {
      console.log(this.chooseStartDay, this.chooseEndDay);
      console.log(this.startDay, this.endDay);
      console.log(this.chooseWeekIndex);
      this.startDay = this.chooseStartDay;
      this.endDay = this.chooseEndDay;
      this.weekIndex = this.chooseWeekIndex = 3;
      this.getWeekDateList(this.startDay, this.endDay);
      // this.startDay = this.chooseStartDay;
      // this.endDay = this.chooseEndDay;
      // this.weekIndex = this.chooseWeekIndex;
    },
    // 获取周课次
    getWeekDateList(start, end) {
      let prevList = [];
      let nextList = [];
      let sumList = [];
      let nowWeekChoose;
      for (let i = 0; i < 7; i++) {
        if (i < 3) {
          prevList[i] = start + 7 * (3 - i);
          nextList[i] = end + 7 * (3 - i);
        } else if (i === 3) {
          prevList[i] = start;
          nextList[i] = end;
        } else {
          prevList[i] = start - 7 * (i - 3);
          nextList[i] = end - 7 * (i - 3);
        }
      }

      prevList.forEach((item, index) => {
        sumList.push({
          label:
            getWeekDate(item) +
            " ~ " +
            getWeekDate(nextList[index]) +
            this.$t("el.myTimeTable.weeklySchedule"),
          value: index,
          start: getWeekDate(item),
          end: getWeekDate(nextList[index]),
        });
        console.log(this.nowWeekDay.split("~")[0].indexOf(getWeekDate(item)));
        if (this.nowWeekDay.split("~")[0].indexOf(getWeekDate(item)) > -1) {
          nowWeekChoose = index;
        }
      });

      this.weekData = sumList;
      if (this.weekIndex !== null) {
        this.nowWeekDay =
          this.weekData[this.weekIndex].start +
          " ~ " +
          this.weekData[this.weekIndex].end +
          "周课表";
      } else {
        if (nowWeekChoose) {
          this.weekIndex = this.chooseWeekIndex;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.week-start-end {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  /deep/ .el-select-dropdown__list {
    text-align: center;
    position: relative;
    padding: 16px 0;
    i {
      color: #6049ff;
      font-size: 16px;
      cursor: pointer;
      position: absolute;
      background: #fff;
      height: 20px;
      z-index: 999;
      left: 50%;
    }
    .el-icon-caret-top {
      top: 5px;
    }
    .el-icon-caret-bottom {
      bottom: 2px;
    }
    .el-select-dropdown__item {
      padding: 0;
      margin: 0 26px;
      height: 44px;
      line-height: 44px;
      &:not(:last-of-type) {
        border-bottom: 1px solid #e6e6e6;
      }
      &:hover,
      &.hover {
        background-color: #fff;
      }
    }
  }
  /deep/ .el-select-dropdown__wrap {
    max-height: 350px;
  }

  /deep/ .el-select-dropdown__item.selected {
    color: #6049ff;
    font-weight: normal;
  }
  /deep/ .el-button--primary.is-plain {
    color: #6049ff;
    border-color: #6049ff;
    background-color: #fff;
  }
  &-select {
    /deep/ .el-input {
      width: 270px;
      & ::placeholder {
        color: #323232;
      }
    }
    /deep/ .el-input__inner {
      border: none;
      color: #323232;
      font-weight: bold;
      font-size: 15px;
      text-align: end;
    }
    /deep/ .el-select__caret {
      color: #323232;
      font-weight: bold;
    }
    /deep/ .el-select-dropdown {
      transform-origin: center bottom !important;
      position: absolute;
      top: auto !important;
      left: 0px !important;
    }
    /deep/ .el-popper[x-placement^="top"] .popper__arrow::after {
      bottom: 1px;
      margin-left: -6px;
      border-top-color: transparent;
      border-bottom-width: 0;
    }
  }
}
</style>
