export default {
  props: {
    typeList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      types: {
        className: "班级",
        staffName: "老师",
        classroomName: "教室",
      },
    };
  },
  render: function (h) {
    let label = "";
    let labels = [];
    for (let key in this.types) {
      // 遍历对象的所有属性，包括原型链上的所有属性
      if (this.types.hasOwnProperty(key)) {
        // 判断是否是对象自身的属性，而不包含继承自原型链上的属性
        console.log(key); // 键名
        this.typeList.forEach((ele) => {
          if (key === ele) {
            labels.push(this.types[key]);
          }
        });
      }
    }
    label = labels.join("、");
    return <span>{label}</span>;
  },
};
